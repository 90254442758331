/**
 * Model definitions for data table use.
 */
import {ITEM_TEMPLATES} from "@/core/models/common";
import ADDRESS from "@/core/models/address";
import {CUSTOMER, CUSTOMER_CONTACT} from "@/core/models/customer";
import INGREDIENT from "@/core/models/ingredient";
import PACKAGING from "@/core/models/packaging";
import QUOTE from "@/core/models/quote";
import {RECIPE, RECIPE_ITEM} from "@/core/models/recipe";
import SUPPLIER from "@/core/models/supplier";
import USER from "@/core/models/user";

const MODELS = {
    ADDRESS,
    CUSTOMER,
    CUSTOMER_CONTACT,
    INGREDIENT,
    PACKAGING,
    SUPPLIER,
    QUOTE,
    RECIPE,
    RECIPE_ITEM,
    USER,
};

export default MODELS;

export {MODELS, ITEM_TEMPLATES};
