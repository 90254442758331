import {US_CA_REGIONS} from "./common.js";

/**
 * Address client-side Model.
 * @type {{apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{hidden: boolean, editable: boolean, update: {handler(*, *): void, watch: string[]}, sortable: boolean, title: string, type: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, value: string, required: boolean},null,null,null], name: string, icon: string, title: string}}
 */
const ADDRESS = {
    name:'Addresses',
    apiRoot:'address',
    title:'name',
    icon:'address-book',
    auth:'admin',
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            title:'Address Search',
            editable:true,
            sortable:false,
            hidden:true,
            value:'_address_search',
            type:'lookup_address',
            /**
             * On selection of address search result, set the other fields appropriately from the selection.
             */
            update:{
                watch:['_address_search'],
                handler(record, column){
                    const address = record[column.value];
                    if (!address){
                        return;
                    }

                    for (const [key, value] of Object.entries(address)){
                        delete record[key];
                        if (value){
                            record[key] = value;
                        }
                    }
                }
            },
        },
        {
            value:'name',
            title:'Name',
            editable:true,
            sortable:true,
            required:true,
            rules:[
                (v) => !!v || "Name is required.",
                (v) => v && v.length < 100 || "Name must be 100 or less characters long.",
            ],
        },
        {
            title:'Street Number',
            editable:true,
            sortable:true,
            value:'street_number',
            required:true,
            type:'number',
            rules:[
                (v) => !!v || "Street number is required.",
                (v) => v && /^\d*$/.test(v) || "Street number allows only digits.",
            ],
        },
        {
            title:'Street',
            editable:true,
            sortable:false,
            value:'street',
            required:true,
            rules:[
                (v) => !!v || "Street is required.",
                (v) => v && v.length < 255 || "Street must be 255 or less characters long.",
            ],
        },
        {
            title:'City',
            editable:true,
            sortable:false,
            value:'city',
            required:true,
            rules:[
                (v) => !!v || "City is required.",
                (v) => v && v.length < 100 || "City must be 100 or less characters long.",
            ],
        },
        {
            title:'State',
            editable:true,
            sortable:true,
            value:'state_code',
            required:true,
            type:'autocomplete',
            autocomplete:{
                items:US_CA_REGIONS,
                itemTitle:'extended',
                itemValue:'iso',
            },
            rules:[
                (v) => !!v || "State is required.",
            ],
        },
        {
            title:'Country',
            editable:true,
            sortable:true,
            value:'country_code',
            required:true,
            type:'select',
            select:{
                itemTitle:'name',
                itemValue:'code',
                items:[
                    {name:'United States of America', code:'us'},
                    {name:'Canada', code:'ca'}
                ],
            },
            rules:[
                (v) => !!v || "Country is required.",
            ],
        },
        {
            title:'Zip Code',
            editable:true,
            sortable:true,
            value:'postal_code',
            required:true,
            rules:[
                (v) => !!v || "Zip code is required.",
                (v) => v && v.length < 15 || "Zip code must be 15 or less characters long.",
                // TODO: Rules for zip and postal code validation?
            ],
        },
    ],
};

export default ADDRESS;
