/**
 * Constants intended for use in various places throughout the application, centrally organized.
 */

/**
 * Email regex for validation, with thanks to the Chromium project.
 * @type {RegExp}
 */
const EMAILREG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Define the events valid for the global event bus.
 * @type {Readonly<{AUTH: {LOGOUT: string, REFRESH: string, LOGIN: string}}>}
 */
const EVENTS = Object.freeze({
    AUTH:{
        LOGIN:'login.auth',
        REFRESH:'refresh.auth',
        LOGOUT:'logout.auth',
    },
    RECORD:{
        ASSC_CREATED:'assc_created.record',
        CREATED:'created.record',
        LOADING:'loading.record',
        NEW:'new.record',
        RESET:'reset.record',
        SET:'set.record',
        DUPLICATE:'duplicate.record',
        UPDATE:'update.record',
        DELETE:'delete.record',
    },
    SNACK:{
        MSG:'msg.snack',
        DISMISS:'hide.snack',
    },
    LOADIALOG:{
        SHOW:'show.loadialog',
        HIDE:'hide.loadialog',
    }
});

/**
 * Define the DSN's for sentry for the staging/production environment.
 * @type {Readonly<{PRODUCTION: string}>}
 */
const SENTRY = Object.freeze({
    PRODUCTION:'https://3996301b0a5c4a87b7e3950346c79dfa@o53202.ingest.sentry.io/6204785',
});

export {
    EVENTS,
    SENTRY,
    EMAILREG,
};
