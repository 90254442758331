import vent from "@/utils/Vent";

const _WORKER = 'cacheWorker';

/**
 * Attempt to register the worker, and log either the success or failure to the console.
 * @param {String} worker
 */
function registerWorker(worker) {
    window.navigator.serviceWorker.register('/worker-' + worker + '.js').then((reg) => {
        console.log('Registration successful for worker ' + worker + ', with scope: ' + reg.scope);
    }, (error) => {
        console.log('Service Worker registration failed for worker: ', worker, error);
    });
}

/**
 * Broadcast messages to all subscribed listeners.
 * @param {MessageEvent} event
 */
function handleMessage(event) {
    vent.trigger(`worker.${event.data.message}`, event);

    console.log(event, event.data.message, `worker.${event.data.message}`);
}

/**
 * Install service workers.
 */
function installWorker() {
    if ('serviceWorker' in window.navigator) {
        // Listen for messages broadcasted by any service worker
        window.navigator.serviceWorker.addEventListener('message', handleMessage);

        window.addEventListener('DOMContentLoaded', () => {
            registerWorker(_WORKER);
        });
    }
}

export default installWorker;
