/**
 * Packaging client-side model.
 */
const PACKAGING = {
    name:'Packaging',
    apiRoot:'packaging',
    title:'name',
    icon:'cubes',
    auth:'admin',
    columns:[
        {
            title:'ID',
            editable:false,
            sortable:true,
            value:'id',
        },
        {
            title:'Name',
            editable:true,
            sortable:true,
            value:'name',
        },
    ],
};

export default PACKAGING;
