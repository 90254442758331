import * as Sentry from "@sentry/vue";

function sentry(app) {
    Sentry.init({
        app,
        dsn: "https://8cdd9b4817b10b2f01be8fcaeccf1b9a@o53202.ingest.us.sentry.io/4507057975721984",
        integrations: [],
    });
}

export default sentry;
