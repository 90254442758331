import {round as _round} from "lodash-es";

/**
 * Ingredient client-side model.
 * @type {{apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, value: string, required: boolean},{autocomplete: {clearable: boolean, returnObject: boolean, itemTitle: string, itemValue: string, model: string}, editable: boolean, rules: *[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},null], name: string, icon: string}}
 */
const INGREDIENT = {
    name:'Ingredients',
    apiRoot:'ingredient',
    icon:'carrot',
    auth:'admin',
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            title:'Name',
            editable:true,
            sortable:true,
            value:'name',
            required:true,
            rules:[
                (v) => !!v || "Name is required.",
                (v) => v && v.length && v.length > 0 && v.length < 101 || "Name must be between 1 and 100 characters.",
            ],
        },
        {
            title:'Label',
            editable:true,
            sortable:true,
            value:'label',
            required:false,
            rules:[
                (v) => ((v) ? v.length && v.length < 101 || "Label must be less than 100 characters." : true),
            ],
        },
        {
            title:'Supplier',
            editable:true,
            sortable:true,
            value:'supplier',
            sortBy:'supplier__name',
            required:true,
            type:'autocomplete',
            autocomplete:{
                model:'SUPPLIER',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
            },
            rules:[
                (v) => !!v || "Supplier is required.",
            ],
        },
        {
            title:'Minimum Order (KG)',
            editable:true,
            sortable:true,
            value:'min_kg',
            required:true,
            type:'number',
            rules:[
                (v) => !!v || "Minimum KG is required.",
                (v) => v > 0 || "Minimum KG must be a positive number.",
            ],
        },
        {
            title:'Cost (per KG)',
            editable:true,
            sortable:true,
            value:'cost_per_kg',
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Cost is required.",
            ],
        },
        {
            title:'Landed Cost (per KG)',
            editable:true,
            sortable:true,
            value:'landed_cost_per_kg',
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Landed cost is required.",
            ],
            update:{
                watch:['cost_per_kg'],
                handler(record, column){
                    const key = column.value,
                        cost = record.cost_per_kg;

                    record[key] = _round(cost * 1.4, 2);
                },
            },
        },
        {
            title:'Probiotic',
            value:'is_probiotic',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'boolean',
        },
        {
            title:'CFU/g',
            value:'cfu',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'number',
            rules:[
                (v) => v !== "" && Number.isInteger(+v) && v >= 0 || "CFU must be a positive integer.",
                (v) => v >= 0 && v < 32767 || "CFU must be between 0 and 32767.",
            ],
        },
        {
            title:'Enzyme/Vitamin',
            value:'is_enzyme',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'boolean',
        },
        {
            title:'Units/g',
            value:'epg',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'number',
            rules:[
                (v) => v !== "" && Number.isInteger(+v) && v >= 0 || "Units/g must be a positive integer.",
                (v) => v >=0 && v < 2e9 || "Units/g must be between 0 and 2000000000.",
            ],
        },
        {
            title:'Enzyme Unit Label',
            value:'enzyme_unit',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            rules:[
                (v) => v && !!v.length || "Unit label is required.",
                (v) => v && v.length <= 10 || "Unit label cannot exceed 10 characters.",
            ],
        },
        {
            title:'Active Percentage',
            value:'is_active_perc',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'boolean',
        },
        {
            title:'Active Percentage',
            value:'active_perc',
            editable:true,
            sortable:false,
            hidden:true,
            required:true,
            type:'number',
            rules:[
                (v) => v !== "" && Number.isInteger(+v) && v >= 0 || "Units/g must be a positive integer.",
                (v) => v >=0 && v <= 100 || "Units/g must be between 0 and 100, inclusive.",
            ],
        },
    ],
};

export default INGREDIENT;
