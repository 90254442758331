import comm from "@/utils/comm";

/**
 * Quote client-side model.
 * @type {{apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{autocomplete: {returnObject: boolean, itemTitle: string, itemValue: string, model: string}, editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, sortable: boolean, title: string, type: string, value: string, required: boolean}], name: string, icon: string, title: string}}
 */
const QUOTE = {
    name:'Quotes',
    apiRoot:'quote',
    title:'id',
    icon:'file-invoice-dollar',
    columns:[
        {
            title:'ID',
            value:'id',
            sortable:true,
            editable:false,
        },
        {
            title:'Customer',
            value:'customer',
            sortBy:'customer__name',
            editable:true,
            sortable:true,
            required:true,
            type:'autocomplete',
            autocomplete:{
                model:'CUSTOMER',
                itemTitle:'name',
                itemValue:'id',
                returnObject:true,
            },
            rules: [
                (v) => !!v || "Customer is required.",
            ],
        },
        {
            title:'Prepared For',
            value:'customer_contact',
            editable:true,
            sortable:false,
            required:false,
            type:'select',
            select: {
                itemTitle: 'name',
                itemValue: 'id',
                returnObject: true,
                items:[],
                noDataText:'Select a Customer first.',
            },
            /**
             * On selection of a customer, populate this column's select items with the appropriate set of contact data,
             * pre-selecting the first result.
             */
            update:{
                watch:['customer'],
                handler(record, column){
                    const key = column.value,
                        customer = record.customer;

                    // @FIXME: We're modifying the column itself here. Replace with something more graceful.
                    column.select.items = [];
                    record[key] = null;
                    if (!customer){
                        return;
                    }

                    comm.request({
                        url:'/api/contact:customer/',
                        method:'GET',
                        data:{
                            customer:customer.id,
                            page_size:5,
                        },
                    }).then((res) => res.json()).then((res) => {
                        column.select.items = res.results;
                        if (column.select.items.length){
                            record[key] = column.select.items[0];
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                },
            },
        },
        {
            title:'Prepared On',
            value:'date',
            sortable:true,
            editable:true,
            required:true,
            type:'date',
            rules: [
                (v) => !!v || "Date is required.",
            ],
        },
        {
            title:'Valid Until',
            editable:true,
            sortable:true,
            value:'valid_until',
            required:true,
            type:'date',
            rules: [
                (v) => !!v || "Expiration is required.",
            ],
            /**
             * On update, ensure that valid_until is set to a date at least one day more than
             * the date value of this record.
             */
            update:{
                watch:['date', 'valid_until'],
                handler(record, column){
                    const validDate = new Date(record[column.value]),
                        date = new Date(record.date);

                    if (!date){ return; }
                    if (!validDate || validDate < date){
                        record[column.value] = new Date(+date + 24*60*60*1000);
                    }
                },
            },
        },
        {
            title:'Chew Mass (mg)',
            value:'chew_mass',
            editable:true,
            sortable:true,
            required:true,
            type:'number',
            slider:{
                min:1000,
                max:6000,
                step:500,
                thumbLabel:'always',
                showTicks:true,
            },
            rules:[],
        },
        {
            title:'Labor Cost',
            value:'labor_cost',
            editable:true,
            sortable:true,
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Labor Cost is required.",
                (v) => v && v >= 0 || "Labor Cost must be a positive number.",
            ],
        },
        {
            title:'Package Fill Weight',
            value:'package_mass',
            editable:true,
            sortable:true,
            required:false,
            type:'number',
            rules:[
                (v) => v && v > 0 || "Mass must be a positive number.",
                (v) => v <= 1000 || "Mass must not be more than 1 kg.",
            ],
        },
        {
            title:'Label Weight',
            value:'display_mass',
            editable:true,
            sortable:true,
            required:false,
            type:'number',
            rules:[
                (v) => v && v > 0 || "Mass must be a positive number.",
                (v) => v <= 1000 || "Mass must not be more than 1 kg.",
            ],
        },
        {
            title:'Package Type',
            value:'package_type',
            editable:true,
            sortable:false,
            required:false,
            type:'select',
            select:{
                model:'PACKAGING',
                itemTitle:'name',
                itemValue:'id',
                returnObject:true,
            },
            rules:[
                (v) => !!v || "Packaging is required.",
            ],
        },
        {
            title:'Batch Mass (Kg)',
            value:'batch_mass',
            editable:true,
            sortable:true,
            required:true,
            type:'number',
            slider:{
                thumbLabel:'always',
                showTicks:true,
            },
            rules:[],
        },
        {
            title:'Margin (%)',
            value:'margin',
            editable:true,
            sortable:true,
            required:true,
            type:'number',
            slider:{
                min:0,
                max:200,
                step:5,
                thumbLabel:'always',
                showTicks:false,
            },
            rules:[],
        },
        {
            title:'Cost Multiplier (%)',
            value:'cost_multiplier',
            editable:true,
            sortable:false,
            required:false,
            type:'number',
            slider:{
                min:0,
                max:30,
                step:5,
                thumbLabel:'always',
                showTicks:false,
            },
            rules:[],
        },
        {
            title:'Volume Discounts',
            value:'volume_margins',
            editable:true,
            sortable:false,
            required:false,
            type:'select',
            select:{
                // Default values for volume discounts for certain batch masses.
                // @SEE https://gitlab.artlogic.com/Bimini/QuoteTool/-/issues/57
                items:[0, 40, 10, 5, 5, 5, 2.5, 2.5],
            },
            rules:[],
        },
        {
            title:'Volume Start',
            value:'volume_range',
            hidden:true,
            editable:true,
            sortable:false,
            required:false,
            type:'number',
            slider:{
                min:0,
                max:7,
                step:1,
                thumbLabel:'always',
                showTicks:true,
            },
            rules:[],
        },
        {
            title:'Packaging Cost',
            value:'packaging_cost',
            editable:true,
            sortable:true,
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Packaging Cost is required.",
                (v) => v && v >= 0 || "Packaging Cost must be a positive number.",
            ],
        },
        {
            title:'Shipping Cost',
            value:'shipping_cost',
            editable:true,
            sortable:true,
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Shipping Cost is required.",
                (v) => v && v >= 0 || "Shipping Cost must be a positive number.",
            ],
        },
        {
            title:'Label Cost',
            value:'label_cost',
            editable:true,
            sortable:true,
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Label Cost is required.",
                (v) => v && v >= 0 || "Label Cost must be a positive number.",
            ],
        },
        {
            title:'Overhead',
            value:'overhead_cost',
            editable:true,
            sortable:true,
            required:true,
            type:'currency',
            rules:[
                (v) => !!v || "Overhead is required.",
                (v) => v && v >= 0 || "Overhead must be a positive number.",
            ],
        },
        {
            title:'Comments',
            value:'comment',
            editable:true,
            sortable:false,
            required:false,
            type:'textarea',
            attrs:{

            },
        },
        {
            title:'Recipe',
            value:'recipe',
            sortBy:'recipe__name',
            editable:true,
            sortable:true,
            required:true,
            type:'submodel',
            submodel:{
                model:'RECIPE',
                relation:'recipe',
            },
            autocomplete:{
                model:'INGREDIENT',
                itemTitle:'name',
                itemValue:'id',
                bind:'ingredient',
                returnObject:true,
            },
        },
    ],
};

export default QUOTE;
