import {createApp} from 'vue';

// Smooth scroll polyfill for browsers that don't support scroll behaviour.
// @See: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
import smoothscroll from 'smoothscroll-polyfill';

import vuetify from "@/plugins/vuetify";
import router from "@/core/router";
import comm from "@/plugins/comm";
import vent from "@/plugins/vent";
import sentry from "@/plugins/sentry";
import fai from "@/plugins/fontawesome";
import installWorker from "@/utils/installWorkers";
import {unlockAudio} from "@/utils/SFX";
import safearea from "@/utils/safeArea";

import App from "./App";

// Our actual Vue application
const vue = createApp(App);

// Install service worker
installWorker();

// Init Sentry monitoring
sentry(vue);

window.addEventListener('DOMContentLoaded', () => {
    // Calculate safe area on platforms that support it.
    // This must be done before mounting vue, so that the value is available to vue components.
    safearea.initialize();

    // Register plugins and mount our view to the root element.
    vue
        .use(vuetify)
        .use(router)
        .use(comm)
        .use(vent)
        .use(fai)
        .mount('#app');

    // Unlock audio at the earliest opportunity.
    unlockAudio();

    // Polyfill smooth scrolling.
    smoothscroll.polyfill();
});
