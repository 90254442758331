import USRegions from "iso3166-2-db/regions/US/en.json";
import CARegions from "iso3166-2-db/regions/CA/en.json";

import {EMAILREG} from "@/utils/constants";

/**
 * Array of objects with details of a US region (state), here mapped to include an `extended` property that we
 * can use in our autocomplete to allow a user to search by full name or iso abbreviation.
 */
const US_CA_REGIONS = USRegions.map(
    (region) => { return {...region, extended:`${region.name} (${region.iso})`}; }
).concat(
    CARegions.map((region) => { return {...region, extended:`${region.name} (${region.iso})`}; })
);

/**
 * Validate email via regex, with thanks to chromium.
 * @See http://jsfiddle.net/ghvj4gy9/embedded/result,js/
 * @param vin
 * @returns {boolean|string}
 * @private
 */
function email_validator(vin){
    return EMAILREG.test(vin) || "Email address is invalid.";
}

/**
 * Hash of templates used to display the contents of auto-completes, comboboxes and selects.
 * These template strings are interpreted by DEntry to determine what format to use to display the
 * selected item(s).
 */
const ITEM_TEMPLATES = {
    ADDRESS:'address',
    ADDRESS_CHIP:'address-chip',
};

export {
    US_CA_REGIONS,
    ITEM_TEMPLATES,
    email_validator,
};
