const _af_buffers = new Map(),
    _audioCtx = new (window.AudioContext || window.webkitAudioContext)();
let _isUnlocked = false;

/**
 * A shim to handle browsers which still expect the old callback-based decodeAudioData,
 * notably iOS Safari - as usual.
 * @param arraybuffer
 * @returns {Promise<any>}
 * @private
 */
function _decodeShim(arraybuffer) {
    return new Promise((resolve, reject) => {
        _audioCtx.decodeAudioData(arraybuffer, (buffer) => {
            return resolve(buffer);
        }, (err) => {
            return reject(err);
        });
    });
}

/**
 * Some browsers/devices will only allow audio to be played after a user interaction.
 * Attempt to automatically unlock audio on the first user interaction.
 * Concept from: http://paulbakaus.com/tutorials/html5/web-audio-on-ios/
 * Borrows in part from: https://github.com/goldfire/howler.js/blob/master/src/howler.core.js
 */
function unlockAudio() {
    if (_isUnlocked) {
        return;
    }

    // Scratch buffer for enabling iOS to dispose of web audio buffers correctly, as per:
    // http://stackoverflow.com/questions/24119684
    const _scratchBuffer = _audioCtx.createBuffer(1, 1, 22050);

    // Call this method on touch start to create and play a buffer,
    // then check if the audio actually played to determine if
    // audio has now been unlocked on iOS, Android, etc.
    const unlock = function(e) {
        // Create an empty buffer.
        const source = _audioCtx.createBufferSource();
        source.buffer = _scratchBuffer;
        source.connect(_audioCtx.destination);

        // Play the empty buffer.
        if (typeof source.start === 'undefined') {
            source.noteOn(0);
        } else {
            source.start(0);
        }

        // Calling resume() on a stack initiated by user gesture is
        // what actually unlocks the audio on Android Chrome >= 55.
        if (typeof _audioCtx.resume === 'function') {
            _audioCtx.resume();
        }

        // Setup a timeout to check that we are unlocked on the next event loop.
        source.onended = function() {
            source.disconnect(0);

            // Update the unlocked state and prevent this check from happening again.
            _isUnlocked = true;

            // Remove the touch start listener.
            document.removeEventListener('touchstart', unlock, true);
            document.removeEventListener('touchend', unlock, true);
            document.removeEventListener('click', unlock, true);
        };
    };

    // Setup a touch start listener to attempt an unlock in.
    document.addEventListener('touchstart', unlock, true);
    document.addEventListener('touchend', unlock, true);
    document.addEventListener('click', unlock, true);
}

/**
 * Allow the requester to load a new sfx, specifying a file to load.
 * We store the decoded audio data for future (re-)use.
 * @param {string} sfxFile
 * @returns {Promise<AudioBuffer>}
 */
async function load(sfxFile) {
    if (_af_buffers.has(sfxFile)) {
        return _af_buffers.get(sfxFile);
    }

    const _sfxFile = await fetch(new URL(sfxFile, window.origin).toString());
    const arraybuffer = await _sfxFile.arrayBuffer();
    let audiobuffer;

    try {
        audiobuffer = await _audioCtx.decodeAudioData(arraybuffer);
    } catch (e) {
        // Browser wants older callback based usage of decodeAudioData
        audiobuffer = await _decodeShim(arraybuffer);
    }

    _af_buffers.set(sfxFile, audiobuffer);

    return _af_buffers.get(sfxFile);
}

/**
 * Play the specified file, loading it first - either retrieving it from the saved buffers, or fetching
 * it from the network.
 * @param sfxFile
 * @returns {Promise<AudioBufferSourceNode>}
 */
function play(sfxFile) {
    return load(sfxFile).then((audioBuffer) => {
        const sourceNode = _audioCtx.createBufferSource();
        sourceNode.buffer = audioBuffer;
        sourceNode.connect(_audioCtx.destination);
        sourceNode.start();

        return sourceNode;
    });
}

export default play;
export {unlockAudio, load, play};
