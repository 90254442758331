// Views
import {isString} from "lodash-es";

import NotFound from "@/views/NotFound";
import NotAuthorized from "@/views/NotAuthorized";
import UnderConstruction from "@/views/UnderConstruction";

import Login from '@/views/Login.vue';
import PasswordRecovery from "@/views/PasswordRecovery.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import DataInput from '@/views/DataInput';

import MODELS from "@/core/models";

/**
 * Application Routes, handled by router.js, utilizing vue-router.
 * Routes are imported by the router, and authentication handled at the router level.
 * @see https://router.vuejs.org/
 * @see core/router.js
 *
 */
export default [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            needsAuth: false
        },
    },
    {
        path: '/user/recovery',
        name: 'passwordRecovery',
        component: PasswordRecovery,
        meta: {
            needsAuth: false,
        },
    },
    {
        path: '/user/reset/:sjson',
        name: 'passwordReset',
        component: PasswordReset,
        props:true,
        meta: {
            needsAuth: false,
        },
    },
    {
        path: '/home',
        name: 'home',
        redirect: {name: 'quotes'}
    },
    // Generate a route for each of our models, using a specified named view if specified, an identically named view
    // if no name is specified, or the generic DataInput component by default.
    ...Object.keys(MODELS).map((key) => {
        const model = MODELS[key],
            name = model.name.toLowerCase();

        return {
            path: `/${name}`,
            name,
            /**
             * Dynamic import for component, falling back to the generic DataInput component if we can't
             * import the specified or named component.
             */
            component(){
                const component = model.component;
                return import(`@/views/${isString(component) ? component : model.name}`).catch(() => {
                    return Promise.resolve(DataInput);
                });
            },
            meta:{
                needsAuth: ('auth' in model) ? model.auth : true,
            },
            props(route){
                return {
                    model:key,
                    user:route.meta.user,
                };
            },
        };
    }),
    {
        path: '/soon',
        name: 'todo',
        component: UnderConstruction,
    },
    {
        path: '/403',
        name: '403',
        component: NotAuthorized,
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
    },
    {
        // 404 / Catch all
        path: '/:m(.*)*',
        redirect: {
            name: '404',
        }
    },
];
