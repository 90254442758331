import {email_validator} from "@/core/models/common";

/**
 * User client-side model.
 * @type {{apiRoot: string, softDelete: boolean, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, rules: ((function(*): *)|(function(*): (boolean|string))|*)[], sortable: boolean, title: string, type: string, value: string, required: boolean}], name: string, icon: string, title: string}}
 */
const USER = {
    name:'Users',
    apiRoot:'users',
    title:'email',
    softDelete:false,
    icon:'user',
    auth:'admin',
    columns:[
        {
            title:'ID',
            editable:false,
            sortable:true,
            value:'id',
        },
        {
            title:'First Name',
            editable:true,
            sortable:true,
            value:'first_name',
        },
        {
            title:'Last Name',
            editable:true,
            sortable:true,
            value:'last_name',
        },
        {
            title:'Email',
            editable:true,
            sortable:true,
            required:true,
            value:'email',
            type:'email',
            rules:[
                (v) => !!v || "Email is required.",
                email_validator,
            ],
        },
    ],
};

export default USER;
