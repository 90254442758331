/**
 * Simple Auth container, containing details about the currently authenticated user, if any.
 */

import {comm, CommError} from "@/utils/comm";
import {EVENTS} from "@/utils/constants";
import vent from "@/utils/Vent";

const AUTH_TOKEN = 'AuthToken';

/**
 * Auth levels of interest.
 * @type {{SUPER: number, NONE: number, USER: number}}
 */
const AUTH_LEVELS = {
    NONE:0,
    USER:1,
    SUPER:2,
};

class Auth{
    constructor(){
        this._authToken = void 0;
        this._user = void 0;
        this._activeRequest = void 0;
        this._waitForToken = void 0;
    }

    /**
     * Get the auth token, fetching it from local storage if available and not already present in this object.
     * @returns {string}
     */
    get token(){
        return this._authToken || (this._authToken = window.localStorage.getItem(AUTH_TOKEN));
    }

    /**
     * Set the token. Passing undefined will remove the token from the auth and
     * from local storage.
     * Call token set listener if set.
     * @param {string|undefined} token
     */
    set token(token){
        if (token === void 0){
            this._authToken = void 0;
            window.localStorage.removeItem(AUTH_TOKEN);
            return;
        }

        this._authToken = token;
        window.localStorage.setItem(AUTH_TOKEN, token);

        if (this._waitForToken){
            this._waitForToken.call(this);
        }
    }

    /**
     * Return cached user object if available, or else attempt to
     * refresh user from the server and return.
     * If a previous call has been made to user that caused a refresh,
     * we return that same promise, so that multiple calls to refresh the
     * user don't result in multiple requests to the server.
     * If the auth token is not yet available, wait for it to become available,
     * and return a promise that resolves when we can request the user.
     * @returns {Promise<object>}
     */
    get user(){
        if (this._user){
            return Promise.resolve(this._user);
        }

        if (!this.token){
            this._activeRequest = new Promise((resolve) => {
                this._waitForToken = () => {
                    this._waitForToken = void 0;
                    this._activeRequest = this.refresh().then((user) => {
                        resolve(user);
                    });
                };
            });
        }

        return (this._activeRequest) ?
            this._activeRequest :
            (this._activeRequest = this.refresh());
    }

    /**
     * Convenience method for common permissions check.
     * @returns {Promise<boolean>}
     */
    get isSuper(){
        return this.user.then((user) => {
            return user.is_superuser === true;
        });
    }

    /**
     * Perform login request, given the user's email and password.
     * @param email
     * @param password
     * @returns {Promise<any>}
     */
    login(email, password){
        return comm.json({
            url: '/api/login/',
            method: 'POST',
            data: {
                email: email,
                password: password,
            },
        }).then((res) => {
            if (!res.ok) {
                throw new CommError("Failed to Login.", res);
            }

            return res.json();
        }).then((json) => {
            this.token = json.token;
            vent.trigger(EVENTS.AUTH.LOGIN);
            return this.token;
        });
    }

    /**
     * Perform request for email-based password recovery flow.
     * @param {String} email
     */
    recovery(email){
        return comm.json({
            url: '/api/users/recovery/',
            method: 'POST',
            data: {
                email,
            },
        }).then((res) => {
            if (!res.ok) {
                throw new CommError("Request Failed.", res);
            }

            return res.json();
        });
    }

    /**
     * Perform request for password reset.
     * @param {String} password New password
     * @param {String} sjson Signed JSON object for user.
     */
    reset(password, sjson){
        return comm.json({
            url: '/api/users/reset/',
            method: 'POST',
            data: {
                password,
                sjson,
            },
        }).then((res) => {
            if (!res.ok) {
                throw new CommError("Request Failed.", res);
            }

            return res.json();
        });
    }

    /**
     * Logging out is simply removing the auth token and cached user.
     */
    logout(){
        this.token = void 0;
        this._user = void 0;
        this._activeRequest = void 0;
        vent.trigger(EVENTS.AUTH.LOGOUT);
    }

    /**
     * (Re-)Request the info on the currently logged in user from the server.
     * @returns {Promise<any>}
     */
    refresh(){
        return comm.request({
            url:'/api/users/me/',
            method:'GET'
        }).then((res) => {
            if (!res.ok){
                throw new CommError("Failed to Refresh.", res);
            }

            return res.json();
        }).then((user) => {
            this._user = user;
            vent.trigger(EVENTS.AUTH.REFRESH);
            return user;
        });
    }
}

// Construct and export auth singleton.
const auth = new Auth();

export default auth;
export {
    AUTH_LEVELS,
    AUTH_TOKEN,
    auth,
};
