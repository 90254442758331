import {email_validator, ITEM_TEMPLATES} from "@/core/models/common";

/**
 * Borrowed from the URLValidator regex in Django.
 * @see https://docs.djangoproject.com/en/4.0/_modules/django/core/validators/
 * @type {RegExp}
 */
const REG_URL_HOST = /(\w(?:\w-]{0,61}\w)?(?:\.(?!-)[\w-]{1,63}(?<!-))*\.(?!-)[\w-]{2,63}(?<!-)\.?|localhost)(?::\d{1,5})?(?:[/?#]\S*)?$/is;

/**
 * Supplier Contact client-side model.
 * @type {{}}
 */
const SUPPLIER_CONTACT = {
    name:'Supplier Contacts',
    apiRoot:'supplier:customer',
    hidden:true,
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            value:'customer',
            title:'Customer',
            editable:true,
            sortable:false,
        },
        {
            value: 'name',
            title: 'Name',
            editable: true,
            sortable: true,
            required: true,
        },
        {
            value:'email',
            title:'Email',
            editable:false,
            sortable:true,
            required:false,
            type:'email',
            rules:[
                (v) => ((v) ? email_validator(v) : true),
            ],
        },
        {
            value:'phone',
            title:'Primary Phone',
            editable:true,
            sortable:false,
            required:false,
            type:'tel',
            rules:[
                // @TODO: Telephone validation
            ],
        },
    ],
};

/**
 * Supplier client-side model.
 * @type {{apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, value: string, required: boolean},{autocomplete: {clearable: boolean, returnObject: boolean, itemTitle: string, itemValue: string, model: string}, editable: boolean, rules: *[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: *[], sortable: boolean, title: string, type: string, value: string, required: boolean}], name: string, icon: string}}
 */
const SUPPLIER = {
    name:'Suppliers',
    apiRoot:'supplier',
    icon:'industry',
    auth:'admin',
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            title:'Name',
            editable:true,
            sortable:true,
            value:'name',
            required:true,
            rules:[
                (v) => !!v || "Name is required.",
                (v) => v && v.length && v.length > 0 && v.length < 101 || "Name must be between 1 and 100 characters.",
            ],
        },
        {
            title:'Address',
            editable:true,
            sortable:false,
            value:'address',
            required:false,
            type:'autocomplete',
            autocomplete:{
                model:'ADDRESS',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
                template:ITEM_TEMPLATES.ADDRESS,
            },
            rules:[

            ],
        },
        {
            title:'URL',
            editable:true,
            sortable:false,
            value:'web_address',
            type:'url',
            required:false,
            attrs:{
                placeholder:'https://your.url.com',
            },
            rules:[
                (v) => {
                    return (!v || (v.length && /^https?:\/\//.test(v))) ||
                        "URL must begin with 'http(s)://' protocol.";
                },
                (v) => {
                    return (!v || (v.length && REG_URL_HOST.test(v))) ||
                        "Enter a valid URL.";
                },
            ],
        },
    ],
};

export default SUPPLIER;

export {SUPPLIER, SUPPLIER_CONTACT};
