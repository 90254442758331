import {round as _round} from "lodash-es";

/**
 * Client-side model representing a Recipe.
 * @type {{apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean}], name: string}}
 */
const RECIPE = {
    name: 'Recipes',
    apiRoot: 'recipe',
    icon:'utensils',
    auth:'admin',
    columns: [
        {
            title:'ID',
            value:'id',
            sortable:true,
            editable:false,
        },
        {
            title:'Name',
            value:'name',
            sortable:true,
            editable:true,
            required:true,
            rules:[
                (v) => !!v && v.length && v.length < 100 || "Name is required and must be less than 100 characters.",
            ],
        },
        {
            title:'Mass',
            value:'mass',
            sortable:true,
            editable:true,
            required:true,
            type:'number',
            slider:{
                min:1000,
                max:6000,
                step:500,
                thumbLabel:'always',
                showTicks:true,
            },
            rules:[
                (v) => !!parseInt(v, 10) || "Mass must be an integer > 0.",
            ],
        },
        {
            title:'Items',
            value:'items',
            hidden:true,
            sortable:false,
            editable:true,
            required:false,
            type:'submodel',
            submodel:{
                model:"RECIPE_ITEM",
                relation:'recipe',
                columns:[
                    {
                        title:'ID',
                        value:'id',
                        sortable:true,
                        editable:false,
                        hidden:true,
                    },
                    {
                        value:'recipe',
                        hidden:true,
                        editable:false,
                    },
                ],
            },
        },
    ],
};

/**
 * Client-side model representing a recipe item.
 * @type {{hidden: boolean, apiRoot: string, columns: [{editable: boolean, sortable: boolean, title: string, value: string},{autocomplete: {clearable: boolean, returnObject: boolean, itemTitle: string, itemValue: string, model: string}, editable: boolean, rules: *[], sortable: boolean, title: string, type: string, value: string},{autocomplete: {clearable: boolean, returnObject: boolean, itemTitle: string, itemValue: string, model: string}, editable: boolean, rules: *[], sortable: boolean, title: string, type: string, value: string},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},{editable: boolean, rules: (function(*): *)[], sortable: boolean, title: string, type: string, value: string, required: boolean},null], name: string}}
 */
const RECIPE_ITEM = {
    name:'Recipe Items',
    apiRoot:'recipe:item',
    hidden:true,
    columns:[
        {
            title:'ID',
            value:'id',
            sortable:true,
            editable:false,
        },
        {
            title:'Recipe',
            value:'recipe',
            sortable:true,
            editable:false,
            type:'autocomplete',
            autocomplete:{
                model:'RECIPE',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
            },
            rules:[

            ],
        },
        {
            title:'Ingredient',
            value:'ingredient',
            sortable:false,
            editable:true,
            type:'autocomplete',
            autocomplete:{
                model:'INGREDIENT',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
            },
            rules:[

            ],
        },
        {
            title:'Mass',
            value:'mass',
            sortable:true,
            editable:true,
            required:true,
            type:'number',
            rules:[
                (v) => ((v ?? false) !== false) && v >= 0 || "Mass must be a positive number.",
                (v) => _round(v, 2) === Number(v) || "Mass allows 2 decimals of precision.",
            ],
        },
        {
            title:'Margin',
            value:'margin',
            sortable:true,
            editable:true,
            required:true,
            type:'number',
            rules:[
                (v) => v >= 0 || "Margin must be an integer >= 0.",
            ],
        },
        {
            title:'Active',
            value:'is_active_ingredient',
            sortable:true,
            editable:true,
            required:false,
            type:'boolean',
        },
    ],
};

export {
    RECIPE,
    RECIPE_ITEM,
};
