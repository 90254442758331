import {email_validator, ITEM_TEMPLATES} from "@/core/models/common";


const CUSTOMER = {
    name:'Customers',
    apiRoot:'customer',
    icon:'user-tie',
    auth:'admin',
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            value:'name',
            title:'Name',
            editable:true,
            sortable:true,
            required:true,
            rules:[
                (v) => v && v.length <= 100 || "Name is required and must be less than 100 characters.",
            ],
        },
        {
            value:'billing_address',
            title:'Billing Address',
            editable:true,
            sortable:false,
            required:false,
            type:'autocomplete',
            autocomplete:{
                model:'ADDRESS',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
                template:ITEM_TEMPLATES.ADDRESS,
            },
            rules:[

            ],
        },
        {
            value:'shipping_address',
            title:'Shipping Address',
            editable:true,
            sortable:false,
            required:false,
            type:'autocomplete',
            autocomplete:{
                model:'ADDRESS',
                itemTitle:'name',
                itemValue:'id',
                clearable:true,
                returnObject:true,
                template:ITEM_TEMPLATES.ADDRESS,
            },
            rules:[

            ],
        },
        {
            value:'contacts',
            title:'Contacts',
            editable:true,
            hidden:true,
            type:'submodel',
            submodel:{
                model:'CUSTOMER_CONTACT',
                relation:'customer',
                columns:[
                    {
                        value:'id',
                        title:'ID',
                        hidden:true,
                    },
                    {
                        value:'customer',
                        title:'Customer',
                        hidden:true,
                    },
                ],
            },
        },
    ],
};

const CUSTOMER_CONTACT = {
    name:'Customer Contacts',
    apiRoot:'contact:customer',
    hidden:true,
    columns:[
        {
            value:'id',
            title:'ID',
            editable:false,
            sortable:true,
        },
        {
            value:'customer',
            title:'Customer',
            editable:true,
            sortable:false,
        },
        {
            value: 'name',
            title: 'Name',
            editable: true,
            sortable: true,
            required: true,
            rules:[
                (v) => v && v.length <= 100 || "Required, 100 chars max.",
            ],
        },
        {
            value:'email',
            title:'Email',
            editable:true,
            sortable:true,
            required:false,
            type:'email',
            rules:[
                (v) => ((v) ? email_validator(v) : true),
            ],
        },
        {
            value:'phone',
            title:'Phone',
            editable:true,
            sortable:false,
            required:false,
            type:'tel',
            rules:[
                // @TODO: Telephone validation
            ],
        },
        {
            value:'is_primary',
            title:'Primary',
            editable:true,
            sortable:true,
            required:false,
            // @Note: Radio is only valid within submodels
            type:'radio',
            width:'2em',
            align:'start',
        },
    ],
};

export default CUSTOMER;
export {CUSTOMER, CUSTOMER_CONTACT};
