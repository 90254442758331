// Application Router, utilizing vue-router.
// See: https://router.vuejs.org/

import {createRouter, createWebHistory, NavigationGuardNext} from "vue-router";

import {isArray, isFunction} from "lodash-es";

import auth from './auth.js';

import routes from "@/core/routes";


const router = createRouter({
    history: createWebHistory('/app/'),
    routes,
    /**
     * Return saved position, if any, and otherwise ensure route transitions always display from 0,0.
     * @param to
     * @param from
     * @param savedPosition
     */
    scrollBehavior(to, from, savedPosition){
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

/**
 * The record meta field `needsAuth` can be a boolean, or it can be a string
 * indicating the level of required auth, in which case we need to check that
 * the user has the appropriate permissions level.
 * @param {object} user
 * @param {object} needsAuth
 * @param {NavigationGuardNext} next
 */
function handleNeedsAuthLevel(user, needsAuth, next){
    if (needsAuth === true && user){
        return next();
    }

    switch(needsAuth){
        case 'admin': // Intentional fallthrough
        case 'super':
            auth.isSuper.then((isSuper) => {
                (isSuper === true) ? next() : next({name:'403'});
            });
            break;
        default:
            // Deny by default
            next({name:'403'});
            break;
    }
}

/**
 * Perform authentication checks before navigation.
 */
router.beforeEach((to, from, next) => {
    for (const record of to.matched){

        // Bypass login view if we are already authenticated
        if (record.name === 'login' && auth.token){
            return next({
                replace: true,
                name: 'home'
            });
        }

        // Enforce permissions
        if (!record.meta?.needsAuth){
            return next();
        }

        if (!auth.token){
            // Require login

            return next({
                name: 'login',
                query: {redirect: to.fullPath},
            });
        }else{
            auth.user.then((user) => {
                // Add user to meta for later reference, and handle authentication level
                to.meta.user = user;
                handleNeedsAuthLevel(user, record.meta.needsAuth, next);
            });
        }
    }
});

/**
 * Map a single route name, or array of names, to a function.
 * @param {Array|String} defs
 * @param {Function} func
 * @returns {Object}
 */
function mapRouteDefs(defs, func){
    if (isArray(defs)){
        const ob = {};
        for (const route of defs){
            ob[route] = func;
        }
        return ob;
    }

    return {[defs]:func};
}

/**
 * Default handler for responding to route definitions.
 * Expects route definitions to be passed in, and expects a default route to define the
 * default state.
 * @param {object} routeDefs
 */
function makeRouteDefsHandler(routeDefs){
    if (!(isFunction(routeDefs.default))){
        throw new Error("A default route definition must be specified.");
    }

    return function(to, from){
        routeDefs.default.call(this);

        const handler = routeDefs[to.name];
        if (handler && isFunction(handler)){
            handler.call(this, to, from);
        }
    };
}

export default router;
export {router, mapRouteDefs, makeRouteDefsHandler};
